import React, { Component } from 'react';
import { List, Datagrid, TextField, EmailField, UrlField, FormDataConsumer, useGetOne } from 'react-admin';
import { Edit, SimpleForm, TextInput, DateInput, Create, NumberInput, ReferenceInput, SelectInput, TabbedForm, FormTab } from 'react-admin';
//import { useForm } from 'react-final-form';

export const UserList = props => (
  <List  {...props} sort={{ field: '_id', order: 'DESC' }} title='Bokningar'>
      <Datagrid rowClick={ListCLick}>
          <TextField source="_id" />
          <TextField source="service" label="Tjänst"/>
          <TextField source="timestampString" label="Bokningsdag"/>
          <TextField source="namn" />
          <TextField source="adress" />
          <TextField source="ort" />
          <EmailField source="email" />
      </Datagrid>
  </List>
);

export const UserFlyttList = props => (
  <List  {...props} >
      <Datagrid rowClick='edit'>
          <TextField source="_id" />
          <TextField source="service" label="Tjänst"/>
          <TextField source="namn" />
          <TextField source="adress" />
          <TextField source="ort" />
          <EmailField source="email" />
      </Datagrid>
  </List>
);

var UserL;

const ListCLick = (id,  basePath, record) => {
  console.log('basePath: ' + basePath);
  let edit = '';
  if (record.service === 'flyttstadning') {
    basePath = 'bookingRequests/flyttstadning';
    console.log(record);
    console.log('flytt: ' + basePath);
    UserL = record.service;
  } else if (record.service === 'provstadning'){
    UserL = record.service;
  } else if (record.service === 'engangsstadning') {
    UserL = record.service;
  }
  return 'edit';
}


 const UserFlytt = props => (
    <FormTab label="Flyttstädning" {...props}>
      <TextField source="id" />
      <SelectInput source="service" choices={[
        { id: 'flyttstadning', name: 'Flyttstädning'},
        { id: 'provstadning', name: 'Provstäding'},
        { id: 'engangsstadning', name: 'Engångsstädning'},
      ]} />
      <TextInput source="antal-kvadrat" label="Antal kvadrat" />
      <TextInput source="tidflytt" label="Tid"/>
      <DateInput source="dateField" label="Datum" />
    </FormTab>
);

const UserProv = props => (
    <FormTab label="Provstädning" {...props}>
      <TextField source="id" />
      <SelectInput source="service" choices={[
        { id: 'flyttstadning', name: 'Flyttstädning'},
        { id: 'provstadning', name: 'Provstäding'},
        { id: 'engangsstadning', name: 'Engångsstädning'},
      ]} />
      <TextInput source="antal-kvadrat" label="Antal kvadrat" />
      <TextInput source="tid" label="Tid"/>
      <DateInput source="dateField" label="Datum" />
    </FormTab>
);

const UserUppfoljning = props => (
    <FormTab label="Uppförljning">
      <TextInput source="anstalld" label="Anställd" />
      <SelectInput source="blevKund" choices={[
        { id: 'True', name: 'Ja'},
        { id: 'False', name: 'Nej'},
      ]} />
      <SelectInput source="aterkoppling" label="Typ av återkoppling" choices={[
        { id: 'email', name: 'Email'},
        { id: 'telefon', name: 'Telefon'},
        { id: 'sms', name: 'Sms'},
      ]} />
      <TextInput source="kommentar" />
    </FormTab>
);

const UserInfo = props => (
  <FormTab label="Kundinformation">
    <TextInput source="namn" />
    <NumberInput source="personnummer" />
    <TextInput source="telefonnummer" />
    <TextInput source="email" type="email"/>
    <TextInput source="adress" />
    <NumberInput source="postnummer" />
    <TextInput source="ort" />
    <TextInput source="portkod" />
  </FormTab>
);

export const UserFlyttEdit = props => {
return(
    <Edit {...props}>
      <TabbedForm >
      <FormTab label="Kundinformation">
          <UserInfo />
      </FormTab>
      <FormTab label="Flyttstädning">
        <UserFlytt/>
      </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export const UserProvEdit = props => {
return(
    <Edit {...props}>
      <TabbedForm >
      <FormTab label="Kundinformation">
          <UserInfo />
      </FormTab>
      <FormTab label="Provstädning">
        <UserProv/>
      </FormTab>
      <FormTab label="Uppföljning">
        <UserUppfoljning/>
      </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export const UserEdit = ({record, ...props}) => {


      return (
        <Edit {...props}>
          <TabbedForm >

          <FormTab label="Kundinformation">
              <UserInfo />
          </FormTab>

          {UserL === 'flyttstadning' && (
            <FormTab label="Flyttstädning">
              <UserFlytt/>
            </FormTab>)}

          {UserL === 'provstadning' && (
            <FormTab label="Provstädning">
              <UserProv {...props}/>
            </FormTab>)}

          {UserL === 'provstadning' && (
            <FormTab label="Uppföljning">
              <UserUppfoljning {...props}/>
            </FormTab>
            )
          }

          </TabbedForm>
        </Edit>
    )
};

export function UserEdit2({record, ...props}) {
      return (
        <Edit {...props}>
          <TabbedForm >
          <FormTab label="Kundinformation">
              <UserInfo />
          </FormTab>

          {UserL === 'flyttstadning' && (
            <FormTab label="Flyttstädning">
              <UserFlytt/>
            </FormTab>)}

          {UserL === 'provstadning' && (
            <FormTab label="Provstädning">
              <UserProv {...props}/>
            </FormTab>)}

          {UserL === 'provstadning' && (
            <FormTab label="Uppföljning">
              <UserUppfoljning {...props}/>
            </FormTab>
            )
          }

          </TabbedForm>
        </Edit>
    )
};
  export const UserCreate = props => (
      <Create {...props}>
        <SimpleForm>
          <TextInput source="namn" />
          <TextInput source="adress" />
          <TextInput source="ort" />
          <TextInput source="email" />
        </SimpleForm>
      </Create>
    );
