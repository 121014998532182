import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({

    palette: {
      primary: {
        main: '#3adaa2',
        contrastText: '#fcfcfc',
      },
      secondary: {
        main: '#653bd9',
        contrastText: '#fcfcfc',
      },
    },
});

export default Theme;
