import React, { Component } from 'react';
import { Admin, Resource, EditGuesser, ListGuesser } from "react-admin";
import { UserList, UserEdit2, UserCreate, UserFlyttEdit, UserProvEdit } from './Users.js'
import jsonServerProvider from "./dataProvider.js";
import ListIcon from '@material-ui/icons/List';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import MyLayout from './MyLayout';
import Theme from './theme.js';
//import { firebaseConfig } from './FIREBASE_CONFIG.js';

const config = {
  apiKey: "AIzaSyDcU6BcCVz1hx2xlWrEPHUHcw0V1Csn9YQ",
  authDomain: "hemkartdb.firebaseapp.com",
  databaseURL: "https://hemkartdb.firebaseio.com",
  projectId: "hemkartdb",
  storageBucket: "hemkartdb.appspot.com",
  messagingSenderId: "202716587074",
  appId: "1:202716587074:web:b5dba3d1de3155f8f6b9db",
  measurementId: "G-6XZ5H8EHTR"
};

const options = {};

const authProvider = FirebaseAuthProvider(config, options);
const dataProvider = jsonServerProvider("https://api.hemkart.se");

class App extends Component {

  render() {
    var UserL = '';
    return (
      <Admin title="Hemkärt" theme={Theme} layout={MyLayout} dataProvider={dataProvider} authProvider={authProvider} >
        <Resource options={{ label: 'Alla bokningar' }} name="bookingRequests/allabokningar" list={UserList} edit={UserEdit2} icon={ListIcon} />
        <Resource options={{ label: 'Flyttstädningar' }} name="bookingRequests/flyttstadning" list={UserList} edit={UserFlyttEdit} icon={ListIcon} />
        <Resource options={{ label: 'Provstädning' }} name="bookingRequests/provstadning" list={UserList} edit={UserProvEdit} icon={ListIcon} />
      </Admin>
    )
  }
}


export default App
